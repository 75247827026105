import {
  CURRENT_ACCOUNT_CREATE_FAIL,
  CURRENT_ACCOUNT_CREATE_REQUEST,
  CURRENT_ACCOUNT_CREATE_RESET,
  CURRENT_ACCOUNT_CREATE_SUCCESS,
  CURRENT_ACCOUNT_DELETE_FAIL,
  CURRENT_ACCOUNT_DELETE_REQUEST,
  CURRENT_ACCOUNT_DELETE_RESET,
  CURRENT_ACCOUNT_DELETE_SUCCESS,
  CURRENT_ACCOUNT_DETAILS_FAIL,
  CURRENT_ACCOUNT_DETAILS_REQUEST,
  CURRENT_ACCOUNT_DETAILS_SUCCESS,
  CURRENT_ACCOUNT_EDIT_FAIL,
  CURRENT_ACCOUNT_EDIT_REQUEST,
  CURRENT_ACCOUNT_EDIT_RESET,
  CURRENT_ACCOUNT_EDIT_SUCCESS,
  CURRENT_ACCOUNT_LIST_FAIL,
  CURRENT_ACCOUNT_LIST_REQUEST,
  CURRENT_ACCOUNT_LIST_SUCCESS,
  CURRENT_ACCOUNT_ACTIVITY_CREATE_FAIL,
  CURRENT_ACCOUNT_ACTIVITY_CREATE_REQUEST,
  CURRENT_ACCOUNT_ACTIVITY_CREATE_RESET,
  CURRENT_ACCOUNT_ACTIVITY_CREATE_SUCCESS,
  CURRENT_ACCOUNT_ACTIVITY_DELETE_FAIL,
  CURRENT_ACCOUNT_ACTIVITY_DELETE_REQUEST,
  CURRENT_ACCOUNT_ACTIVITY_DELETE_RESET,
  CURRENT_ACCOUNT_ACTIVITY_DELETE_SUCCESS,
  CURRENT_ACCOUNT_ACTIVITY_DETAILS_FAIL,
  CURRENT_ACCOUNT_ACTIVITY_DETAILS_REQUEST,
  CURRENT_ACCOUNT_ACTIVITY_DETAILS_SUCCESS,
  CURRENT_ACCOUNT_ACTIVITY_EDIT_FAIL,
  CURRENT_ACCOUNT_ACTIVITY_EDIT_REQUEST,
  CURRENT_ACCOUNT_ACTIVITY_EDIT_RESET,
  CURRENT_ACCOUNT_ACTIVITY_EDIT_SUCCESS,
  CURRENT_ACCOUNT_ACTIVITY_LIST_FAIL,
  CURRENT_ACCOUNT_ACTIVITY_LIST_REQUEST,
  CURRENT_ACCOUNT_ACTIVITY_LIST_SUCCESS,
  CURRENT_ACCOUNT_LIST_RESET,
} from "../constants/currentAccountConstants";

// @desc Create a current account
export const reducerCreateCurrentAccount = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case CURRENT_ACCOUNT_CREATE_REQUEST:
      return { loading: true };
    case CURRENT_ACCOUNT_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        currentAccount: action.payload,
      };
    case CURRENT_ACCOUNT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CURRENT_ACCOUNT_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

// @desc List current accounts
export function reducerListCurrentAccounts(
  state = { loading: true, currentAccountsList: [] },
  action
) {
  switch (action.type) {
    case CURRENT_ACCOUNT_LIST_REQUEST:
      return { loading: true, ...state };
    case CURRENT_ACCOUNT_LIST_SUCCESS:
      return {
        loading: false,
        currentAccountsList: action.payload.currentAccounts,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case CURRENT_ACCOUNT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CURRENT_ACCOUNT_LIST_RESET:
      return {};
    default:
      return state;
  }
}

// @desc Get details of a current account
export function reducerCurrentAccountDetails(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case CURRENT_ACCOUNT_DETAILS_REQUEST:
      return { loading: true };
    case CURRENT_ACCOUNT_DETAILS_SUCCESS:
      return {
        loading: false,
        detailsCurrentAccount: action.payload,
      };
    case CURRENT_ACCOUNT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// @desc Edit a current account
export function reducerCurrentAccountEdit(state = { success: false }, action) {
  switch (action.type) {
    case CURRENT_ACCOUNT_EDIT_REQUEST:
      return { loading: true };
    case CURRENT_ACCOUNT_EDIT_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case CURRENT_ACCOUNT_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case CURRENT_ACCOUNT_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

// @desc Delete a current account
export function reducerDeleteCurrentAccount(state = {}, action) {
  switch (action.type) {
    case CURRENT_ACCOUNT_DELETE_REQUEST:
      return { loading: true };
    case CURRENT_ACCOUNT_DELETE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case CURRENT_ACCOUNT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CURRENT_ACCOUNT_DELETE_RESET:
      return {};
    default:
      return state;
  }
}

/////////////////////////////////////////////-----currentAccountActivities-----\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

// @desc Create a current account activity
export const reducerCreateCurrentAccountActivity = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case CURRENT_ACCOUNT_ACTIVITY_CREATE_REQUEST:
      return { loading: true };
    case CURRENT_ACCOUNT_ACTIVITY_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        createdCurrentAccountActivity: action.payload,
      };
    case CURRENT_ACCOUNT_ACTIVITY_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CURRENT_ACCOUNT_ACTIVITY_CREATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

// @desc List current accounts activities
export function reducerListCurrentAccountsActivities(
  state = { loading: true, currentAccountsActivitiesList: [] },
  action
) {
  switch (action.type) {
    case CURRENT_ACCOUNT_ACTIVITY_LIST_REQUEST:
      return { loading: true, ...state };
    case CURRENT_ACCOUNT_ACTIVITY_LIST_SUCCESS:
      return {
        loading: false,
        currentAccountsActivitiesList: action.payload.currentAccountsActivities,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case CURRENT_ACCOUNT_ACTIVITY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// @desc Get details of a current account activity
export function reducerCurrentAccountActivityDetails(
  state = { loading: true },
  action
) {
  switch (action.type) {
    case CURRENT_ACCOUNT_ACTIVITY_DETAILS_REQUEST:
      return { loading: true };
    case CURRENT_ACCOUNT_ACTIVITY_DETAILS_SUCCESS:
      return {
        loading: false,
        detailsCurrentAccountActivity: action.payload,
      };
    case CURRENT_ACCOUNT_ACTIVITY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// @desc Edit a current account activity
export function reducerCurrentAccountActivityEdit(
  state = { success: false },
  action
) {
  switch (action.type) {
    case CURRENT_ACCOUNT_ACTIVITY_EDIT_REQUEST:
      return { loading: true };
    case CURRENT_ACCOUNT_ACTIVITY_EDIT_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case CURRENT_ACCOUNT_ACTIVITY_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case CURRENT_ACCOUNT_ACTIVITY_EDIT_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
}

// @desc Delete a current account activity
export function reducerDeleteCurrentAccountActivity(state = {}, action) {
  switch (action.type) {
    case CURRENT_ACCOUNT_ACTIVITY_DELETE_REQUEST:
      return { loading: true };
    case CURRENT_ACCOUNT_ACTIVITY_DELETE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case CURRENT_ACCOUNT_ACTIVITY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CURRENT_ACCOUNT_ACTIVITY_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
