import axios from "axios";
import publicIp from "public-ip";

//REFORMATING STRINGS TO BE USED AS A URL EXTENSION
const convertToUrl = (str) => {
  const trMap = {
    çÇ: "c",
    ğĞ: "g",
    şŞ: "s",
    üÜ: "u",
    ıİ: "i",
    öÖ: "o",
  };
  for (let key in trMap) {
    str = str.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
  }
  return str
    .replace(/[^-a-zA-Z0-9\s]+/gi, "") // remove non-alphanumeric chars
    .replace(/\s/gi, "-") // convert spaces to dashes
    .replace(/[-]+/gi, "-") // trim repeated dashes
    .toLowerCase();
};

//CHECK IF AN OBJECT HAS ANY PROPERTY OR NOT
function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

//ADD 2 DIGIT DECIMALS
function addDecimals(num) {
  return (Math.round(num * 100) / 100).toFixed(2);
}

// GET CLIENT IP ADDRESS
const getClientIp = async () =>
  await publicIp.v4({
    fallbackUrls: ["https://ifconfig.co/ip"],
  });

//CAPITAIZE FIRST LETTER OF EVERY WORD IN A STRING
function capitalizeFirstLetters(str) {
  const arr = str.split(" ");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
}

// RETURN TRUE IF N IS PRIME
function isPrime(n) {
  // Check if n is less than 2 => not prime
  if (n < 2) return false;

  // Loop from 2 to square root of n
  for (let i = 2; i <= Math.sqrt(n); i++)
    // If i is a divisor of n, n is not prime
    if (n % i === 0) return false;

  return true;
}

const calculateTimeLeft = () => {
  let year = new Date().getFullYear();
  let difference = +new Date(`10/01/${year}`) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      //days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      // hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

// CHECK IF CREDIT CARD NUMBER IS VALID
function luhnChecker(code) {
  let revisedCode = code.replace(/ /g, "");
  let len = revisedCode.length;
  let parity = len % 2;
  let sum = 0;
  for (let i = len - 1; i >= 0; i--) {
    let d = parseInt(revisedCode.charAt(i));
    if (i % 2 === parity) {
      d *= 2;
    }
    if (d > 9) {
      d -= 9;
    }
    sum += d;
  }
  let result = sum % 10;
  if (result === 0) {
    return true;
  } else {
    return false;
  }
}

// FIRST NAME PICKER FROM A NAME FIELD
function firstNamePicker(str) {
  let newStr = str.split(" ").join(",");
  let trimmed = newStr.replace(/[, ]+/g, " ").trim();
  let result = trimmed
    .split(" ")
    .slice(0, trimmed.split(" ").length - 1)
    .join(",")
    .replace(/,/g, " ");
  return result;
}

// LAST NAME PICKER FROM A NAME FIELD
function lastNamePicker(str) {
  let newStr = str.split(" ").join(",");
  let trimmed = newStr.replace(/[, ]+/g, " ").trim();
  let result = trimmed
    .split(" ")
    .slice(trimmed.split(" ").length - 1)
    .join(",")
    .replace(/,/g, " ");
  return result;
}

// SCROLL TO TOP ON CLICK
function scrollToTop(value) {
  return window.scrollTo({
    top: value ? value : 0,
    left: 0,
    behavior: "smooth",
  });
}

// SPLIT AN ARRAY BY SIZE
function splitArrayByLength(arr, len) {
  let chunks = [],
    i = 0,
    n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
}

// GET ALL FROM LOCAL STORAGE - WITHOUT KEYS
function allStorageWithoutKeys() {
  var values = [],
    keys = Object.keys(localStorage),
    i = keys.length;

  while (i--) {
    values.push(localStorage.getItem(keys[i]));
  }

  return values;
}

// GET ALL FROM LOCAL STORAGE - WITH KEYS
function allStorageWithKeys() {
  var archive = {}, // Notice change here
    keys = Object.keys(localStorage),
    i = keys.length;

  while (i--) {
    archive[keys[i]] = localStorage.getItem(keys[i]);
  }

  return archive;
}

// GET ALL FROM LOCAL STORAGE - COMPACT LIST
function allStorageCompactList() {
  var archive = [],
    keys = Object.keys(localStorage),
    i = 0,
    key;

  for (; (key = keys[i]); i++) {
    archive.push(key + "=" + localStorage.getItem(key));
  }

  return archive;
}

// REMOVE CERTAIN PREFIXES FROM LOCAL STORAGE
function clearItemFromLocalStorage(key, subStart, subEnd) {
  // CLEAR SPECIFIC LOCAL STORAGE ITEM
  let arr = []; // Array to hold the keys
  // Iterate over localStorage and insert the keys that meet the condition into arr
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).substring(subStart, subEnd) === key) {
      arr.push(localStorage.key(i));
    }
  }
  // Iterate over arr and remove the items by key
  for (let i = 0; i < arr.length; i++) {
    localStorage.removeItem(arr[i]);
  }
  return arr;
}

const findDuplicates = (arr) => {
  let sorted_arr = arr.slice().sort(); // You can define the comparing function here.
  // JS by default uses a crappy string compare.
  // (we use slice to clone the array so the
  // original array won't be modified)
  let results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] === sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
};

// CONVERT NUMBERS INTO TEXT
function tlCevir(sayi) {
  let virgullu = sayi.replace(".", ",");
  let rakam = virgullu
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    .split(",");
  let tamsayi = rakam[0];
  let ondalik = rakam[1] || "";

  var birler = [
    "",
    "BİR",
    "İKİ",
    "ÜÇ",
    "DÖRT",
    "BEŞ",
    "ALTI",
    "YEDİ",
    "SEKİZ",
    "DOKUZ",
  ];
  var onlar = [
    "",
    "ON",
    "YİRMİ",
    "OTUZ",
    "KIRK",
    "ELLİ",
    "ALTMIŞ",
    "YETMİŞ",
    "SEKSEN",
    "DOKSAN",
  ];
  var binler = [
    "",
    "BİN",
    "MİLYON",
    "MİLYAR",
    "TRİLYON",
    "KATRİLYON",
    "KENTİLYON",
  ];
  var sonuc = [];

  var adim = 0;
  for (let i = tamsayi.split(".").length; i > 0; i--) {
    sayi = tamsayi.split(".")[i - 1];
    if (sayi.length === 1) {
      sayi = "00" + sayi;
    }
    if (sayi.length === 2) {
      sayi = "0" + sayi;
    }
    let c = "";

    for (let j = 1; j < sayi.length + 1; j++) {
      if (j === 1 && sayi[j - 1] === 1) {
        c += " YÜZ ";
      } else if (j === 1 && birler[sayi[j - 1]] !== "") {
        c += birler[sayi[j - 1]] + " YÜZ ";
      } else if (j === 2) {
        c += onlar[sayi[j - 1]] + " ";
      } else if (
        j === 3 &&
        tamsayi.length === 5 &&
        sayi[j - 1] === 1 &&
        adim === 1
      ) {
        c += " ";
      } else if (j === 3) {
        c += birler[sayi[j - 1]] + " ";
      }
    }

    if (c !== "") {
      sonuc.push(c + binler[adim]);
    }
    adim++;
  }

  if (sonuc.length !== 0) {
    sonuc = sonuc.reverse().join(" ") + " TL";
  } else {
    sonuc = "";
  }
  if (ondalik.length === 1) {
    ondalik = ondalik + "0";
  }
  if (ondalik !== "") {
    sonuc += " " + onlar[ondalik[0]] + " " + birler[ondalik[1]] + " KR";
  }

  sonuc = sonuc.replace(/ /g, " ").trim();

  return sonuc;
}

// REMOVE DUPLICATES FROM A SIMPLE ARRAY
function removeDuplicatesFromArray(arr) {
  const newArray = [...new Set(arr)];
  return newArray;
}
function removeDuplicatesByProperty(arr, prop) {
  const uniqueMap = new Map();
  const uniqueArray = [];

  for (const item of arr) {
    if (!uniqueMap.has(item[prop])) {
      uniqueMap.set(item[prop], true);
      uniqueArray.push(item);
    }
  }

  return uniqueArray;
}

function removeDuplicatesByOrderSlipNumberProperty(arr, prop) {
  const uniqueMap = new Map();
  const uniqueArray = [];

  for (const item of arr) {
    const orderSlipNumber = item[prop].split("/")[0];

    if (!uniqueMap.has(orderSlipNumber)) {
      uniqueMap.set(orderSlipNumber, true);
      uniqueArray.push(item);
    }
  }

  return uniqueArray;
}

// DATE FORMAT FOR DOCUMENT UPDATES
function dateFormatForUpdates() {
  const date = new Date();
  return `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} , ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

// GROUP AN ARRAY OF OBJECTS BASED ON KEYS
function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
}

// GROUP AN ARRAY OF OBJECTS BASED ON KEYS
function groupBySku(array, key) {
  return array.reduce((hash, obj) => {
    if (
      obj[key].split("-").slice(0, 2).toString().replace(",", "-") === undefined
    )
      return hash;
    return Object.assign(hash, {
      [obj[key].split("-").slice(0, 2).toString().replace(",", "-")]: (
        hash[obj[key].split("-").slice(0, 2).toString().replace(",", "-")] || []
      ).concat(obj),
    });
  }, {});
}

// FUNCTION TO DETECT A FACEBOOK BROWSERID(_fbp) COOKIE IF ANY
function getFbBrowserId() {
  let result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
  if (!(result && result[1])) {
    return null;
  }
  return result[1].length > 1 ? result[1].split(";")[0] : result[1];
}

// FUNCTION TO DETECT A FACEBOOK CLIENTID(_fbc) COOKIE IF ANY
function getFbClientId() {
  let result = /_fbc=(fb\.1\.\d+\..*)/.exec(window.document.cookie);
  if (!(result && result[1])) {
    const queryParams = new URLSearchParams(window.location.search);
    const fbclid = queryParams.get("fbclid");
    if (fbclid) {
      const now = Date.now();
      return (result = `fb.1.${now}.${fbclid}`);
    } else {
      return null;
    }
  } else {
    return result[1].length > 1 ? result[1].split(";")[0] : result[1];
  }
}

// FUNCTION TO DETECT A QUERIES IN URL
function getQueryFromUrl(query) {
  const queryParams = new URLSearchParams(window.location.search);
  const queryTerm = queryParams.get(query);
  if (!queryTerm) {
    return null;
  }
  return queryTerm;
}
function deleteQueryFromUrl(query) {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.delete(query);
}

// SET SCROLL HEIGHT
function setScrollHeight(pathname) {
  const height = getScrollHeight();
  return sessionStorage.setItem(pathname, height);
}

// GET SCROLL HEIGHT
function getScrollHeight() {
  const height = window.scrollY;

  return height;
}

// FLATTEN AN ARRAY THAT CONTAINS ARRAY
function flattenArray(array) {
  const merged = [].concat.apply([], array);

  return merged;
}

// CONVERT ISO 8601 UTC TO UTC+3 IN LOCAL
function convertIsoToLocal(iso, format) {
  if (!iso) return null;

  const timestamp = iso;
  const date = new Date(timestamp).getTime();
  const utcDate = new Date(date);

  if (format === "YYYY-MM-DD") {
    let date = utcDate.toLocaleString().slice(0, 10);
    date = date.split(".");
    return `${date[2]}-${date[1]}-${date[0]}`;
  } else {
    // DD-MM-YYYY HOUR:MINUTE:SECOND
    return utcDate.toLocaleString();
  }
}

// GET DATE IN ISO 8601
function getDateInIso8601() {
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString() + "+00:00";

  return formattedDate;
}

// Gets countries list from restcountries api
async function getCountryList() {
  try {
    const response = await axios.get("/api/adminGeoLocation/countryList");
    const countryList = response.data;
    return countryList;
  } catch (error) {
    const errorMessage =
      error.message || error.response?.data?.error || "Unknown error";
    throw errorMessage;
  }
}

// Gets countries list from restcountries api
async function getTurkeyProvinceAndDstrictList() {
  try {
    const response = await axios.get(
      "/api/adminGeoLocation/turkeyProvinceAndDstrictList"
    );
    const list = response.data;
    return { type: "success", list };
  } catch (error) {
    const errorMessage =
      error.message || error.response?.data?.error || "Unknown error";
    return { type: "error", error: errorMessage };
  }
}

// Get todays date in DD-MM-YYYY format
function getTodaysDate(format) {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // add 1 because months are zero indexed
  const year = today.getFullYear();

  if (format === "DD-MM-YYYY") {
    return `${day}-${month}-${year}`;
  } else if (format === "YYYY-MM-DD") {
    return `${year}-${month}-${day}`;
  }
}

function createNineDigitRandomCode() {
  let code = Math.floor(Math.random() * 900000000 + 100000000);

  code =
    code.toString().slice(0, 3) +
    "-" +
    code.toString().slice(3, 6) +
    "-" +
    code.toString().slice(6, 9);

  return code;
}

// VAT Calculator (RETURNS STRING DUE TO toFixed METHOD CALLED ON RESULTS)
function vatCalculator(vatRate /*number*/, amount /*number*/, type /*string*/) {
  const vat = Number(vatRate) / 100 + 1;

  // excluded removes vat from amount, included adds up vat on amount
  if (type !== "included" && type !== "excluded") {
    return 'type parameter has to be either "included" or "excluded';
  }

  if (type === "included") {
    const vatIncluded = Number(amount) * (vat > 0 ? vat : 1);

    return vatIncluded.toFixed(2);
  } else if (type === "excluded") {
    const vatExcluded = Number(amount) / (vat > 0 ? vat : 1);
    return vatExcluded.toFixed(2);
  }
}

// SHORTEN TABLE TEXTS
function textShortener(text, maxLen) {
  const shortText =
    text?.length > maxLen ? text.slice(0, maxLen) + "..." : text;

  return shortText;
}

// CONVERT SHORT DATE INTO A NEW FORMAT - DD/MM/YYYY
function convertShortDateFormatIntoAnewDateFormat(
  day,
  month,
  year,
  dateFormat
) {
  const formatArray = dateFormat
    .split("/")
    .map((elem) => {
      return `${
        elem === "DD" ? day : elem === "MM" ? month : elem === "YYYY" && year
      }`;
    })
    .toString()
    .replace(/,/g, "/");

  return formatArray;
}

// DUPLICATION WARNING FOR DYNAMICALLY ADDED INPUT FORMS
function duplicationWarningForDynamicFieldsForm(data, arrayProperty, property) {
  // VARIABLES

  const duplicateItemObjs = [];

  const hasDuplicate = data[arrayProperty].some((item, index) => {
    const filteredItems = data[arrayProperty].filter(
      (el, i) => el[property] === item[property] && index !== i
    );
    if (filteredItems.length > 0) {
      filteredItems.forEach((el) => {
        if (!duplicateItemObjs.includes(el[property])) {
          duplicateItemObjs.push(el[property]);
        }
      });
      return true;
    }
    return false;
  });

  return { duplicateItemObjs, hasDuplicate };
}

// It accepts 2 parameter; count is the total "number" to be added 1 and prefix is any letter + zeros exm: SOOOOOO which is "string"
function generateCode(count, prefix) {
  let newCodeWithoutPrefixLength = (count + 1).toString().length;
  let newCodeWithoutPrefix = (count + 1).toString();
  let codePrefix = prefix;
  let codePrefixLength = codePrefix.length;

  const newCode = `${codePrefix.slice(
    0,
    codePrefixLength - newCodeWithoutPrefixLength
  )}${newCodeWithoutPrefix}`;

  return newCode;
}

// CALCULATE DIMENTIONAL WEIGHT IN CM / desi
function calculateDimensionalWeight(width, height, length) {
  const dimensionalWeight =
    (Number(width) * Number(height) * Number(length)) / 5000;

  return Math.ceil(dimensionalWeight);
}

export {
  convertToUrl,
  isEmpty,
  addDecimals,
  getClientIp,
  capitalizeFirstLetters,
  isPrime,
  calculateTimeLeft,
  luhnChecker,
  firstNamePicker,
  lastNamePicker,
  scrollToTop,
  splitArrayByLength,
  allStorageWithoutKeys,
  allStorageWithKeys,
  allStorageCompactList,
  clearItemFromLocalStorage,
  findDuplicates,
  tlCevir,
  removeDuplicatesFromArray,
  removeDuplicatesByProperty,
  removeDuplicatesByOrderSlipNumberProperty,
  dateFormatForUpdates,
  groupByKey,
  groupBySku,
  getFbBrowserId,
  getFbClientId,
  getQueryFromUrl,
  deleteQueryFromUrl,
  setScrollHeight,
  getScrollHeight,
  flattenArray,
  convertIsoToLocal,
  getDateInIso8601,
  getCountryList,
  getTurkeyProvinceAndDstrictList,
  getTodaysDate,
  createNineDigitRandomCode,
  vatCalculator,
  textShortener,
  convertShortDateFormatIntoAnewDateFormat,
  duplicationWarningForDynamicFieldsForm,
  generateCode,
  calculateDimensionalWeight,
};
